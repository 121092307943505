"use client"

import React, { useEffect } from 'react'
import MUITable  from '@mui/joy/Table';
import { useState } from 'react';
import styles from './Table.module.css'
import { useContext } from 'react';
import { pageContext } from '../context';
import { useNavigate } from 'react-router-dom';
import Row from './Row.js'

function createDateFromString(dateTimeString) {
  const [datePart, timePart] = dateTimeString.split(', ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [time, ampm] = timePart.split(' ');

  let hours, minutes, seconds;
  [hours, minutes, seconds] = time.split(':').map(Number);

  if (ampm === 'pm' && hours !== 12) {
    hours += 12;
  } else if (ampm === 'am' && hours === 12) {
    hours = 0;
  }

  return new Date(year, month - 1, day, hours, minutes, seconds);
}




function compareDatetime(a, b) {
  const dateA = createDateFromString(a.DateTimeProcessing)
  const dateB = createDateFromString(b.DateTimeProcessing)

  return new Date(dateB) - new Date(dateA);
}

function compareDatetimeReceived(a, b) {
  const dateA = createDateFromString(a.DateTimeReceived)
  const dateB = createDateFromString(b.DateTimeReceived)

  return new Date(dateB) - new Date(dateA);
}
function compareDatetimeDispatched(a, b) {
  const dateA = createDateFromString(a.DateTimeDispatched)
  const dateB = createDateFromString(b.DateTimeDispatched)

  return new Date(dateB) - new Date(dateA);
}



function Table(props) {
  let [lots, setLots] = useState([])
  let {page, setPage} = useContext(pageContext)
  let navigate = useNavigate()

  useEffect(()=>{
    setLots([])
      fetch(`/lot/${props.status}`, {
        headers: {Frontend: window.location.host},
        cache: 'no-store'
      })
      .then(res=>{
        res.json().then(data=>{
          if(data.error){
          setPage('new')
          navigate('/')
          }
          else{
          if(props.status==='new')
          data.sort(compareDatetimeReceived)
          else if(props.status==='dispatched')
          data.sort(compareDatetimeDispatched)
          else
          data.sort(compareDatetime)
          setLots(data)
          }
        })
      })
  }, [page])

  let stages = {
    'new': 1,
    'processing': 2,
    'ready': 3,
    'dispatched': 4,
    'discarded': 2
  }

  let stage = stages[props.status]

  let element = <img alt='loading' src='../loading.gif'></img>


  if(lots.length>0 && lots[0]==0)
  element = <div className={styles.empty}>
  <img alt='empty' src='../empty.svg'></img>
    <p>No data to display</p>
  </div>



  return (
    <>
      { (lots.length==0 || lots[0]==0) ? <div className={styles.loading}>
        {element}
      </div> :
      <MUITable borderAxis='x' variant="plain" sx={{paddingX: '10px', textAlign: 'center'}}>
    <thead className={styles.center}>
      <tr >
        <th style={{ width: '5%' }} >SNo.</th>
        <th>LotNo / KitCode</th>
        <th>Lot Size</th>
        <th>Received On</th>
        { stage>=2 ?   <th>Processing</th> : ''}
        { stage>=4 ?   <th>Dispatched</th> : ''}
        <th>Print Material</th>
        <th>Status</th>
        { props.status==='processing' ?   <th style={{ width: '7%' }}>Download</th> : ''}
      </tr>
    </thead>
    <tbody className={styles.center}>
      {
        lots.map((lot, index)=>{
          console.log(index)
          return <Row index={index} lot={lot} status={props.status} stage={stage}/>
        })
      }
    </tbody>
  </MUITable>
      
      }
    </>
  
  )
}

export default Table