import React from 'react'
import Sheet from '@mui/joy/Sheet';
import styles from './NewUserPage.module.css'
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';




function NewUserPage() {
    return (
        <div className={styles.main}>
            <Sheet sx={{ width: '40%', borderRadius: '10px', p: '20px'}}>
            <form style={{display: 'flex', flexDirection: 'column', gap: '20px',}} method='POST' action='/user'>
                <Typography level="h5">Register a new user</Typography>
                <Input placeholder="Set a username" required name='username'/>
                <Input placeholder="Set password" type='password' required name='password'/>
                <Button type='submit' onClick={(e)=>{
                        e.target.innerText = 'Adding user...'
                }} >Add user</Button>
                <Link to='/dashboard/admin'>
                    <Button variant='outlined'>Go back</Button>
                </Link>
                </form>
            </Sheet>
        </div>
    )
}

export default NewUserPage