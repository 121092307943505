import React from 'react'
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import styles from './SideBar.module.css';
import NewOrderIcon from './icons/NewOrderIcon';
import ProcessingIcon from './icons/ProcessingIcon';
import DispatchedIcon from './icons/DispatchedIcon';
import { Link} from 'react-router-dom';
import { useContext } from 'react';
import { userContext, pageContext } from '../context';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from './icons/LogoutIcon';

function SideBar() {

    let navigate = useNavigate()
    
    let {user, setUser} = useContext(userContext)
    let {page, setPage } = useContext(pageContext)

    function logout(){
        setUser({})
        setPage('/dashboard/new')
        fetch('/logoutDashboard')
        .then(res=>{
            window.localStorage.setItem('username', '')
            window.localStorage.setItem('role', '')
            navigate('/')
        })
    }


  return (
    <Sheet sx={
        {
            background: '',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: '5%',
            boxSizing: 'border-box'
        }
    }>
        <img className={styles.logo} src='/markex_logo.gif' alt="logo"></img>
        
        <ul>
            <Link to="new"> <li><Button  variant={page =='dashboard/new' ? 'solid' : 'soft'} color="primary" startDecorator={<NewOrderIcon />} onClick={()=>{setPage('dashboard/new')}}>New Order</Button></li> </Link>
            <Link to="processing"> <li><Button variant={page =='dashboard/processing' ? 'solid' : 'soft'} startDecorator={<ProcessingIcon />} onClick={()=>{setPage('dashboard/processing')}} >Processing</Button></li> </Link>
            {/* <Link to="ready"> <li><Button variant={page =='dashboard/ready' ? 'solid' : 'soft'} startDecorator={<ReadyIcon />}  onClick={()=>{setPage('dashboard/ready')}}>Ready to Dispatch</Button></li> </Link> */}
            <Link to="dispatched"> <li><Button variant={page =='dashboard/dispatched' ? 'solid' : 'soft'} startDecorator={<DispatchedIcon />} onClick={()=>{setPage('dashboard/dispatched')}} >Dispatched</Button></li> </Link>
            { window.localStorage.getItem('role')=='admin' ? <Link to="admin"> <li><Button variant={page =='dashboard/admin' ? 'solid' : 'soft'}  onClick={()=>{setPage('dashboard/admin')}} >Users</Button></li> </Link> : ''}
        </ul>

        <Button  sx={{width: '15%'}} startDecorator={<LogoutIcon />} color='danger' variant='outlined' onClick={logout}>Logout {window.localStorage.getItem('username')}</Button>


        </ Sheet>

  )

}
    
export default SideBar