import React from 'react'
import Sheet from '@mui/joy/Sheet';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Alert from '@mui/joy/Alert';
import { useContext } from 'react';
import { pageContext, userContext } from '../context';  
import { useNavigate} from "react-router-dom";
import styles from './LoginPage.module.css'

function LoginPage() {

    let {user, setUser} = useContext(userContext)
    let {setPage} = useContext(pageContext)
    let navigate = useNavigate()

    async function login(event) {
        event.preventDefault()
    
        const formData = new FormData(event.target);
        const dataObject = Object.fromEntries(formData.entries());
        console.log(dataObject);
    
        let res = await fetch('/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataObject)
        })
    
        let data = await res.json()
        if (data.username){
          window.localStorage.setItem('username', data.username)
          window.localStorage.setItem('role', data.role)
          setPage('dashboard/new')
        navigate('/dashboard/new')
        }
        else
        document.querySelector('button').innerText = 'Log in'
    
        setUser(data)
      }
      
  return (
    <Sheet variant='plain' sx={{
        display: 'flex',
        background: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>

    
        <Sheet variant='outlined' sx={{
          width: '30%',
          padding: '30px',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px'
        }}>
        <img src='markex_logo.gif' className={styles.logo}></img>
          { user.error ? <Alert variant='soft' color='danger'>{user.error}</Alert> : '' }
          <form onSubmit={login}  style={{
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px'
          }}>
            <Typography level="h5">Login to the dashboard</Typography>
            <Typography level="h6" fontSize='xs'>Please fill in your credentials to proceed</Typography>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input name='username' type='text' placeholder='Enter username'></Input>
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input name='password' type='password' placeholder='Enter password'></Input>
            </FormControl>
            <Button type='submit' onClick={(e)=>{e.target.innerText="Please wait"}}>Log in</Button>
          </form>
        </Sheet>
        </Sheet>
  )
}

export default LoginPage