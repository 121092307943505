import React from 'react'
import DownloadIcon from './icons/DownloadIcon'
import { useState } from 'react'
import LoadingIcon from './icons/LoadingIcon'


function DownloadButton({download, lotNumber}) {
    let [downloadStatus, setDownloadStatus] = useState('base')
    let el
    if(downloadStatus === 'base')
    el = <DownloadIcon color='none'/>
    else if(downloadStatus==='downloading')
    el = <LoadingIcon />
    else{
    el = <DownloadIcon color='green'/>
    console.log(`tr[lot_number="${lotNumber}"] > td:nth-last-child(2) > ul > li:nth-last-child(1)`);
    document.querySelector(`tr[lot_number="${lotNumber}"] > td:nth-last-child(2) > ul > li:nth-last-child(1)`).click()
    }

  return (
    <td onClick={async ()=>{
        setDownloadStatus('downloading')
        await download(lotNumber)
        setDownloadStatus('downloaded')
        }} style={{cursor: 'pointer'}}>
        {  el }
    </td>
  )
}

export default DownloadButton