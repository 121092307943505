import './App.css';
import { CssVarsProvider} from '@mui/joy/styles';
import { useState } from 'react';
import LoginPage from './components/LoginPage';
import { userContext, pageContext } from './context';
import HomePage from './components/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Table from './components/Table';
import AdminDashboard from './AdminDashboard';
import NewUserPage from './NewUserPage';
import ChangePasswordPage from './ChangePasswordPage';


function App() {

  let [user, setUser] = useState({})
  let url = window.location.pathname.substring(1)
  let [page, setPage] = useState(url)

  return (
    <CssVarsProvider>

      <BrowserRouter>
        <userContext.Provider value={{ user, setUser }}>
        <pageContext.Provider value={{ page, setPage }}>

          <Routes>
            <Route path="/dashboard" element={<HomePage />}>
              <Route path="new" element={<Table status="new"/>} />
              <Route path="processing" element={<Table status="processing"/>} />
              <Route path="ready" element={<Table status="ready"/>} />
              <Route path="dispatched" element={<Table status="dispatched"/>} />
              <Route path="admin" element={<AdminDashboard />}/>
            </Route>

            <Route path="/" element={<LoginPage />}/>
            <Route path="/newuser" element={<NewUserPage />}/>
            <Route path="/changepassword/:username" element={<ChangePasswordPage />}/>

          </Routes>
        </pageContext.Provider>
        </userContext.Provider>
      </BrowserRouter>
    </CssVarsProvider>
  );
}

export default App;
