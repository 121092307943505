import React from 'react'
import UsersTable from './components/UsersTable'
import styles from './AdminDashboard.module.css'
import { Link } from 'react-router-dom'


function AdminDashboard() {
    return (
        <div>
            <UsersTable />
            <Link to='/newuser'>
                <button className={styles.newUserBtn}>Add User</button>
            </Link>
        </div>
    )
}

export default AdminDashboard