import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useState } from 'react';

export default function Status({status, lot_number}) {


  function handleChange(el){
    let newStatus = el.innerText.toLowerCase()

    let dispatchDetails = 'no data yet'

    // if(newStatus=='dispatched')
    // dispatchDetails = prompt('Enter dispatch details for lot '+lot_number)

    if(dispatchDetails.trim()){
    document.querySelector(`[lot_number="${lot_number}"]`).style.background = 'yellow'
    document.querySelector(`[lot_number="${lot_number}"] > td:nth-last-child(2)`).innerText = 'updating'
    fetch(`/updateStatus/${lot_number}/${newStatus}/${dispatchDetails}`, {cache: 'no-store'})
    .then(res=>{
         res.json()
         .then(result=>{
          if(result.success)
          document.querySelector(`[lot_number="${lot_number}"]`).remove()
          else
          alert('Error, please try re-logging in')
         })
         
    }).catch((e)=>{alert('Error occured')})
  }
  else{
    window.location.reload()
  }
    
  }

  let next = {
    'new': 'processing',
    'processing': 'dispatched'
  }

  
 
  return (
     <Select defaultValue={status} onChange={(e)=>{if(e) handleChange(e.target)}}>
     <Option value={status}>{status.charAt(0).toUpperCase()+status.substring(1)}</Option>
      {status!=='dispatched' ? <Option value={next[status]}>{next[status].charAt(0).toUpperCase()+next[status].substring(1)}</Option> : ''}
      {status==='dispatched' && window.localStorage.getItem('role')==='admin' ? <Option value='discarded' sx={{color: 'red'}}>Discarded</Option> : '' }
      {status==='dispatched' && window.localStorage.getItem('role')==='admin' ? <Option value='processing'>Processing</Option> : '' }
    </Select>
  )
}
