import React from 'react'
import Sheet from '@mui/joy/Sheet';
import styles from './NewUserPage.module.css'
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { Link, useParams } from 'react-router-dom';




function ChangePasswordPage() {

    let {username} = useParams()

    return (
        <div className={styles.main}>
            <Sheet sx={{ width: '40%', borderRadius: '10px', p: '20px'}}>
            <form style={{display: 'flex', flexDirection: 'column', gap: '20px',}} method='POST' action='/user'>
                <Typography level="h5">Change password</Typography>
                <Input placeholder="Set a username" required name='username'  value={username}/>
                <Input placeholder="Set a new password" type='password' required name='password' autoFocus/>
                <Button type='submit' onClick={(e)=>{
                        e.target.innerText = 'Changing password...'
                }} >Change</Button>
                <Link to='/dashboard/admin'>
                    <Button variant='outlined'>Go back</Button>
                </Link>
                </form>
            </Sheet>
        </div>
    )
}

export default ChangePasswordPage