import React from 'react'

function formatDateTime(date) {
  date = new Date(date)
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  const year = String(date.getFullYear()).slice(-2);
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12).padStart(2, '0'); // Convert to 12-hour format
  
  return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
}

function ActivityLog({log}) {
  return (
    <div id='activity_log'>
    <table style={{width: '500px'}}>
        <tr>
          <th>User</th>
          <th>Moved to Status</th>
          <th>Date Time</th>
        </tr>
        
        {
            log.map(entry=>{
                return <tr>
          <td>{entry.user}</td>
          <td>{entry.status}</td>
          <td>{formatDateTime(entry.date_time)}</td>
        </tr>
            })
        }
    </table>
    </div>
  )
}

export default ActivityLog