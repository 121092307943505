import React from 'react'
import styles from './HomePage.module.css';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';




function HomePage() {

  return (
    <div className={styles.main}>
        <SideBar />
        <Outlet />
    </div>
  )
}

export default HomePage