import React, { useEffect, useState } from 'react'
import Table from '@mui/joy/Table';
import Switch from '@mui/joy/Switch';
import styles from './UsersTable.module.css'
import Button from '@mui/joy/Button/Button';
import { Link } from 'react-router-dom';

function deleteUser(username){
    fetch(`/user/${username}`, {
        method: 'DELETE'
    })
    .then(res=>{
        res.json().then(data=>{
            window.location.reload()
        })
    })
}

function UsersTable() {

    let [users, setUsers] = useState([])

    useEffect(()=>{
        fetch('/user')
        .then(res=>{
            res.json().then(users=>{
                setUsers(users)
            })
        })
    })

  return (
    <Table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Updated on</th>
                        <th>Delete</th>
                        <th>Change password</th>
                    </tr>
                </thead>
                <tbody>
                   {
                    users.map(user=>{
                        return <tr key={user.username}>
                        <td>{user.username}</td>
                        <td>{user.added}</td>
                        <td><Button variant='outlined' color='danger' onClick={
                            (e)=>{
                                e.target.innerText = 'Deleting...'
                                deleteUser(user.username)
                            }
                        } >Delete</Button></td>
                        <td>
                        <Link to={`/changepassword/${user.username}`}>
                        <Button variant='outlined'>Change password</Button>
                        </Link>
                        </td>
                    </tr>
                    })
                   }
                </tbody>
        </Table>
  )
}

export default UsersTable