import React, { useState } from 'react'
import Chip from '@mui/joy/Chip';
import Status from './Status';
import DownloadButton from './DownloadButton';
import ActivityLog from './ActivityLog';

function decodeText(text){
  const decodedData = atob(text);
  const utf8Decoder = new TextDecoder('utf-8');
  const decodedText = utf8Decoder.decode(new Uint8Array([...decodedData].map(char => char.charCodeAt(0))));
  return decodedText;
}

async function downloadCSV(lotNumber){
    let data = await fetch(`/getLotData/${lotNumber}`)
    data = await data.json()
    let hasKit = data.kit ? true : false
    let hasQrKey = data.hasQrKey
    data = decodeText(data.data).replaceAll('<#quote#>','"')
    console.log(data);
    let blob = new Blob(['\uFEFF','QrId,QrData,LotNumber,PrintFormat\n', data.replace(/\\n/g, '\n')], { type: 'text/csv' });
  
    // Check if data has KitCode
    if(hasKit)
    blob = new Blob(['\uFEFF','QR Id,QR Text,Lot No,Ticket id,MID display name,MID full name,MID Add display name,Address line 1,Address line 2,Address line 3,Address Line 4,Pincode,Merchant mobile no,Kit code,mid,Requested Date,Order Id\n', data.replace(/\\n/g, '\n')], { type: 'text/csv' });
  
    // Check if data has QR Pdf key
    if(hasQrKey)
    blob = new Blob(['\uFEFF','QR Id,QR Text,Lot No,Ticket id,MID display name,MID full name,MID Add display name,Address line 1,Address line 2,Address line 3,Address Line 4,Pincode,Merchant mobile no,Kit Code,MID,Requested Date,PDF Encryption Key,Order Id\n', data.replace(/\\n/g, '\n')], { type: 'text/csv' });
  
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', lotNumber);
    a.click()
  
    return
  }



function Row({lot, index, status, stage}) {

  let [isLogVisible, setIsLogVisible] = useState(false)

  return (
    <>
    <tr key={index} lot_number={lot.LotNumber}>
        <td>{index+1}</td>
        <td>
          <Chip onClick={()=>{window.localStorage.getItem('role')==='admin' && setIsLogVisible(!isLogVisible)}} variant='outlined'>{lot.LotNumber}</Chip>  { lot.KitCode!='none' ? <Chip variant='outlined' color='info'>{lot.KitCode}</Chip> : '' }
        </td>
        <td><Chip variant='outlined'>{lot.LotSize}</Chip></td>
        <td>{lot.DateTimeReceived}</td>
        { stage>=2 ? <td>{lot.DateTimeProcessing}</td> : '' }
        { stage>=4 ? <td>{lot.DateTimeDispatched}</td> : '' }
        <td>{lot.PrintingMaterial}</td>
        <td><Status status={lot.Status} lot_number={lot.LotNumber} changed={false} /></td>
        { status==='processing' ? <DownloadButton download={downloadCSV} lotNumber={lot.LotNumber}/>  : ''}
      </tr>


      { isLogVisible ? <ActivityLog log={lot.log}/> : ''}
      </>
  )
}

export default Row